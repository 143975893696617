import cmfApi from "../index";

const API_URL = "/notifications";

function getNotifications(token, params) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
		params: params
	};

	const URL = API_URL;
	

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

function getCountNotifications(token, params) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
		params: params
	};

	const URL = API_URL+ '/count2';
	

	return new Promise(function (resolve, reject) {
		cmfApi.get(URL, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

function reactNotification(token, payload) {
	const config = {
		headers: {
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	};


	const URL = `${API_URL}/react`;

	return new Promise(function (resolve, reject) {
		cmfApi.post(URL, payload, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { getNotifications, reactNotification, getCountNotifications };
