import * as getPeriods from "@/api/periods/get";

export default {
	namespaced: true,
	state: {
		periods: [],
		error: null,
	},
	getters: {
		readPeriods: (state) => state.periods,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_PERIODS(state, payload) {
			state.periods = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getPeriods({ commit, rootGetters }) {
			let token = rootGetters['loginStore/readToken']
			let getPeriodsRes = await getPeriods
				.getPeriods(token)
				.then((data) => {
					commit("SET_PERIODS", data);
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getPeriodsRes;
		},
	},
};
