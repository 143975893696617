import * as createUser from "@/api/users/create";

export default {
	namespaced: true,
	state: {
		user: {},
		error: null,
	},
	getters: {
		readUser: (state) => state.user,
		readError: (state) => state.error,
	},
	mutations: {
		SET_USER(state, payload) {
			state.user = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async createUser({ commit, getters, rootGetters }) {
			let token = rootGetters["loginStore/readToken"];
			let createUserRes = await createUser
				.createUser(token,getters.readUser)
				.then(() => {
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return createUserRes;
		},
	},
};
