import votesApi from "@/api/votesCount/votesApi";

export const votesActions = async ({ state, commit, rootState }, payload) => {
	const { votesCountId: user_id, votesCountPeriod: period_id } = state,
		{ accessToken } = rootState.loginStore,
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};

	let action;

	try {
		switch (payload) {
			case "getVotesCount":
				action = await votesApi(headers, { user_id, period_id }).then(
					({ data }) => {
						commit("SET_VOTES_COUNT", {});
						if (data) {
							commit("SET_VOTES_COUNT", data);
							return true;
						} else {
							return false;
						}
					}
				);
				break;
			default:
				break;
		}
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("loginStore/SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
