import { getLogin } from "@/api/loginApi";

export const loginActions = async ({ commit, getters }, payload) => {
	const { readCredentials: body, readHash: code } = getters;

	let action;

	try {
		switch (payload) {
			case "getLogin":
				action = await getLogin(body, code).then(({ data }) => {
					if (data) {
						commit("SET_ACCESS_TOKEN", data.token);
						return true;
					} else {
						commit("SET_ACCESS_TOKEN", null);
						return false;
					}
				});
				break;
			default:
				break;
		}
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
