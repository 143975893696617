import * as getTop from "@/api/stats/topUsers";
import Vue from 'vue'

export default {
	namespaced: true,
	state: {
		top: [],
		sort: null,
		filter: null,
		filters: {},
		error: null,
	},
	getters: {
		readTop: (state) => state.top,
		readSort: (state) => state.sort,
		readFilter: (state) => state.filter,
		readFilters: (state) => state.filters,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_TOP(state, payload) {
			state.top = payload;
		},
		SET_SORT(state, payload) {
			state.sort = payload;
		},
		SET_FILTER(state, payload) {
			state.filter = payload;
		},
		SET_FILTERS(state, payload) {
			Vue.set(state, 'filters', payload)
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getTop({ commit, getters, rootGetters }) {
			const token = rootGetters["loginStore/readToken"];
			let getTopRes = await getTop
				.getTop(token, getters.readFilters)
				.then((data) => {
					if (data) {
						const response = data.items || data;
						Array.isArray(response)
							? commit("SET_TOP", response)
							: commit("SET_TOP", [response]);
						return true;
					} else {
						commit("SET_TOP", []);
						commit("SET_TOTAL_PAGES", null);

						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return getTopRes;
		},
	},
};
