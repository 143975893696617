import * as getReport from "@/api/reports/get";

export default {
	namespaced: true,
	state: {
		report: {},
		params: null,
		error: null,
	},
	getters: {
		readReport: (state) => state.report,
		readParams: (state) => state.params,
		readError: (state) => state.error,
	},
	mutations: {
		SET_REPORT(state, payload) {
			state.report = payload;
		},
		SET_PARAMS(state, payload) {
			state.params = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getVotesByUsers({ commit, getters, rootGetters }) {
			let token = rootGetters["loginStore/readToken"];
			let getVotesByUsersRes = await getReport
				.getVotesByUsers(token, getters.params)
				.then((data) => {
					const result = data ? data : null;
					commit("SET_REPORT", result);
					return result;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});

			return getVotesByUsersRes;
		},
	},
};
