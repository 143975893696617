export const SET_VOTES_COUNT = (state, payload) => {
	state.votesCount = payload;
};
export const SET_VOTES_COUNT_ID = (state, payload) => {
	state.votesCountId = payload;
};
export const SET_VOTES_COUNT_PERIOD = (state, payload) => {
	state.votesCountPeriod = payload;
};
export const SET_ERROR = (state, payload) => {
	state.error = payload;
};
