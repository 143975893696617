import * as getNotifications from "@/api/notifications/get";

export default {
	namespaced: true,
	state: {
		id:null,
		notifications: {vote: {items: []}, reaction: {items: []}},
		countNotifications: {vote:0, reaction: 0},
		totalPages:null,
		error: null,
	},
	getters: {
		readTotal: (state) => state.totalPages,
		readId: (state) => state.id,
		readNotifications: (state) => (type) => state.notifications[type],
		readCountVote: (state) =>  state.countNotifications.vote,
		readCountReaction: (state) =>  state.countNotifications.reaction,
		readVotes: (state) => state.notifications.vote,
		readReaction: (state) => state.notifications.reaction,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_TOTAL_PAGES(state, payload) {
			state.totalPages = payload;
		},
		SET_ID(state, payload) {
			state.id = payload;
		},
		ADD_NOTIFICATIONS(state, payload) {
			let data = [...state.notifications[payload.type].items, ...payload.data.items]
			state.notifications[payload.type].items = data;
			state.notifications[payload.type].page = payload.data.page;
			state.notifications[payload.type].size = payload.data.size;
			state.notifications[payload.type].total = payload.data.total;
		},
		SET_NOTIFICATIONS(state, payload) {
			state.notifications[payload.type] = payload.data;
		},
		
		SET_COUNT_NOTIFICATIONS(state, payload) {
			state.countNotifications[payload.type] = payload.count;
		},
		
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async getNotifications({ commit, getters ,rootGetters }, payload = {type: 'vote', status: 'all', page: 1, size: 5}) {
			let token = rootGetters['loginStore/readToken']
			let getNotificationsRes = await getNotifications
				.getNotifications(token, {receiver_id:  getters.readId, type: payload.type, status: payload.status, page: payload.page, size: payload.size})
				.then((data) => {
					if (data) {
						const response = data;
						payload.page == 1
							? commit("SET_NOTIFICATIONS", {data: response, type: payload.type})
							: commit("ADD_NOTIFICATIONS", {data: response, type: payload.type});
						return true;
					} else {
						commit("SET_NOTIFICATIONS", {data: [], type: payload.type});
						return false;
					}
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			try {
				let count = await getNotifications.getCountNotifications(token, {receiver_id:  getters.readId, type: payload.type})
				commit('SET_COUNT_NOTIFICATIONS', count)
				console.log('COUNT', payload.type, count)
			} catch (error) {
				
			}
			return getNotificationsRes;
		},
	},
};
