import { getAuth } from "@/api/authApi";

export const getUser = async ({ commit, rootState }) => {
	const { accessToken } = rootState.loginStore,
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};

	let action;

	try {
		action = await getAuth(headers).then(({ data }) => {
			if (data) {
				commit("SET_PROFILE", data);
				return true;
			} else {
				commit("SET_PROFILE", {});
				return false;
			}
		});
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
