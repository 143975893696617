import Vue from "vue";

const capitalize = (el, value) => {
	return typeof value == "string"
		? (el.innerText = value
				.toLowerCase()
				.trim()
				.split(" ")
				.map((v) => v.length > 2 ? v[0]?.toUpperCase() + v.slice(1) : v ) 
				.join(" "))
		: (el.innerText = value);
};

Vue.directive("capitalizeWords", {
	inserted(el, { value }) {
		if (value) {
			capitalize(el, value);
		}
	},
	componentUpdated(el, { value }) {
		if (value) {
			capitalize(el, value);
		}
	},
});
