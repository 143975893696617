export const SET_ID = (state, payload) => {
	state.id = payload;
};

export const SET_AGENCY = (state, payload) => {
	state.agency = payload;
};

export const SET_AGENCIES = (state, payload) => {
	state.agencies = payload;
};

export const SET_FILTER = (state, payload) => {
	state.filter = payload;
};

export const SET_ERROR = (state, payload) => {
	state.error = payload;
};
