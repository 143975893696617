import cmfApi from "./index";

export const getAgenciesApi = (headers) => cmfApi.get("/agencies", { headers });

export const createAgencyApi = (data, headers) =>
	cmfApi.post("/agencies", data, { headers });

export const updateAgencyApi = (id, data, headers) => {
	return cmfApi.post(`/agencies/${id}`, data, { headers });
};

export const deleteAgencyApi = (id, headers) => {
	return cmfApi.delete(`/agencies/${id}`, { headers });
};
