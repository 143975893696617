import axios from "axios";
import cmfApi from "../index";

const API_URL = "/votes";

function createVote(token,body) {

	const config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`
		},
	};

	return new Promise(function (resolve, reject) {
		cmfApi.post(API_URL, body, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		)
	});
}

function createMasiveVotes(body) {
	let token = localStorage.getItem('ru_token')
	if(token) {
		token = JSON.parse(token)
	}
	const config = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			Authorization: `Bearer ${token.token}`
		},
	};
	let endpoint = `${API_URL}/masive/create`
	return new Promise(function (resolve, reject) {
		cmfApi.post(endpoint, body, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		)
	});
}

export { createVote, createMasiveVotes };

