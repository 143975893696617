export default {
	namespaced: true,
	state: {
		isLoading: false,
	},
	getters: {
		isLoading: (state) => state.isLoading,
	},
	mutations: {
		SET_LOADING(state, payload) {
			state.isLoading = payload;
		},
	},
};
