import cmfApi from "../index";

const API_URL = "/culture/elements";

function updateCulture(token,id, body) {
	const config = {
		headers: {
			Accept: "application/json",
			'Content-Type': "application/json",
			Authorization: `Bearer ${token}`,
		},
	};

	return new Promise(function (resolve, reject) {
		cmfApi.post(`${API_URL}/${id}`, body, config).then(
			(response) => {
				var result = response.data;
				resolve(result);
			},
			(error) => {
				reject(error);
			}
		);
	});
}

export { updateCulture };
