import * as deleteCultureById from "@/api/cultures/delete";

export default {
	namespaced: true,
	state: {
		id: null,
		error: null,
	},
	getters: {
		readId: (state) => state.id,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async deleteCulture({ commit, getters, rootGetters }) {
			let token = rootGetters['loginStore/readToken'];
			let deleteCultureRes = await deleteCultureById
				.deleteCulture(token,getters.readId)
				.then(() => {
					return true;
				})
				.catch((error) => {
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});

			return deleteCultureRes;
		},
	},
};
