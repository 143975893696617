//CORE
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import moment from 'moment';
import VueMoment from 'vue-moment';

// Load Locales ('en' comes loaded by default)
require('moment/locale/es');



//STYLES
// import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/es';

import "./assets/styles/index.scss";
import "./assets/styles/tailwind.css";


//PLUGINS
import _ from "lodash";
import "./plugins/fontawesome";
import "./plugins/sweetAlert";
import "./plugins/crypto";
import "./plugins/tour";

//DIRECTIVES
import "./directives/capitalizeWords";
import "./directives/capitalizeInput";

// Choose Locale
moment.locale('es');

Vue.use(VueMoment, { moment });
Vue.config.productionTip = false;

new Vue({
	store,
	router,
	render: (h) => h(App),
}).$mount("#app");
