export const SET_ID = (state, payload) => {
	state.id = payload;
};

export const SET_REGION = (state, payload) => {
	state.region = payload;
};

export const SET_REGIONS = (state, payload) => {
	state.regions = payload;
};

export const SET_FILTER = (state, payload) => {
	state.filter = payload;
};

export const SET_ERROR = (state, payload) => {
	state.error = payload;
};
