import {
	getAgenciesApi,
	createAgencyApi,
	updateAgencyApi,
	deleteAgencyApi,
} from "@/api/agenciesApi";

import { getAgencies } from "../../../api/agencies/get";
import { updateAgency } from   "../../../api/agencies/update";
import { createAgency } from "../../../api/agencies/create";
import { deleteAgency } from "../../../api/agencies/delete";

export const agencyActions = async ({ state, commit, rootState }, payload) => {
	const { agency } = state,
		{ id } = agency,
		{ accessToken } = rootState.loginStore,
		headers = {
			Authorization: `Bearer ${accessToken}`,
		};

	let action;

	try {
		switch (payload) {
			case "getAgencies":
				action = await getAgencies(accessToken, null, state.filter).then((data) => {
					if (data) {
						commit("SET_AGENCIES", data);
						return true;
					} else {
						commit("SET_AGENCIES", []);
						return false;
					}
				});
				break;
			case "createAgency":
				action = await createAgencyApi(agency, headers);
				break;
			case "updateAgency":
				action = await updateAgencyApi(id, agency, headers);
				break;
			case "deleteAgency":
				action = await deleteAgencyApi(id, headers);
				break;
			default:
				break;
		}
	} catch (error) {
		if (error.response) {
			let message =
				error.response.data.message ||
				error.response.data.detail[0].msg;
			message == "Token expired"
				? commit("loginStore/SET_ACCESS_TOKEN", null, {
						root: true,
				  })
				: message
				? commit("SET_ERROR", message)
				: commit("SET_ERROR", "Algo salió mal");
		} else {
			console.log(error);
			commit("SET_ERROR", error);
		}
	}

	return action;
};
