import Vue from "vue";

const capitalize = (el) => {
	return typeof el.value == "string"
		? (el.value = el.value
				.toLowerCase()
				.trim()
				.split(" ")
				.map((v) => v[0].toUpperCase() + v.slice(1))
				.join(" "))
		: (el.value = el.value);
};

Vue.directive("capitalizeInput", {
	inserted(el) {
		if (el.value) {
			capitalize(el);
		}
	},
});
