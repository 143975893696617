import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

store.commit("loginStore/SET_ACCESS_TOKEN", "restore");
store.commit("auth/SET_PROFILE", "restore");

//ROUTES
const routerOptions = [
	{
		path: "/",
		redirect: "/login",
	},
	{
		path: "*",
		redirect: "/login",
	},
	{
		path: "/logout",
		name: "Logout",
		beforeEnter: (to, from, next) => {
			//REMOVE THE TOKEN FROM LOCAL_STORAGE
			store.commit("loginStore/SET_ACCESS_TOKEN");
			next({ name: "Login" });
		},
	},
	{
		path: "/login",
		name: "Login",
		beforeEnter: async (to, from, next) => {
			const hash = to.query.code;

			const isToken = store.getters["loginStore/readToken"];
			const isAdmin = store.getters["auth/readIsAdmin"];

			isToken && !hash
				? isAdmin
					? next({ name: "Metricas" })
					: next({ name: "Inicio" })
				: next();
		},
	},
	{
		path: "/admin",
		name: "Admin",
		children: [
			{
				path: "metricas",
				name: "Metricas",
			},
			{
				path: "reconocimiento/colaboradores",
				name: "ReconocimientoColaboradores",
			},
			{
				path: "reconocimiento/cultura",
				name: "ReconocimientoCultura",
			},
			{
				path: "colaboradores",
				name: "Colaboradores",
			},
			{
				path: "info-colaborador/:id?",
				name: "InfoColaborador",
			},
			{
				path: "periodos",
				name: "Periodos",
			},
			{
				path: "roles",
				name: "Roles",
			},
			{
				path: "culturas",
				name: "Culturas",
			},
			{
				path: "territorios",
				name: "Territorios",
			},
			{
				path: "regiones",
				name: "Regiones",
			},
			{
				path: "agencias",
				name: "Agencias",
			},
			{
				path: "buscar",
				name: "Buscar",
			},
			{
				path: "detalle-colaborador/:id?",
				name: "DetalleColaborador",
			},
			{
				path: "preguntas-frecuentes",
				name: "PreguntasFrecuentes",
			},
			{
				path: "reconocimientos-masivos",
				name: "ReconocimientosMasivos",
			},
		],
		beforeEnter: async (to, from, next) => {
			const isToken = store.getters["loginStore/readToken"];
			const isAdmin = store.getters["auth/readIsAdmin"];

			isToken
				? isAdmin
					? next()
					: next({ name: "Inicio" })
				: next({ name: "Login" });
		},
	},
	{
		path: "/votacion",
		name: "Votacion",
		children: [
			{
				path: "inicio/:tour?",
				name: "Inicio",
			},
			{
				path: "intro",
				name: "Intro",
			},
			{
				path: "reconocer",
				name: "Reconocer",
			},
			{
				path: "tabla",
				name: "Tabla",
			},
			{
				path: "notificaciones",
				name: "Notificaciones",
			},
			{
				path: "usuario",
				name: "Usuario",
			},
			{
				path: "usuarios",
				name: "Usuarios",
			},
			{
				path: "preguntas-frecuentes",
				name: "Preguntas",
			},
		],
		beforeEnter: async (to, from, next) => {
			const isToken = store.getters["loginStore/readToken"];

			isToken ? next() : next({ name: "Login" });
		},
	},
];

//LAZY_LOAD
const routes = routerOptions.map((r) => {
	if (!r.children) {
		return {
			...r,
			component: () => import(`@/views/${r.name}/Index.vue`),
		};
	} else {
		const children = r.children.map((c) => {
			let childName = c.name;
			c.component = () => import(`@/views/${r.name}/${childName}.vue`);
			return { ...c };
		});

		return {
			...r,
			component: () => import(`@/views/${r.name}/Index.vue`),
			children: [...children],
		};
	}
});

const router = new VueRouter({
	routes,
});

export default router;
