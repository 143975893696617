import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faPenToSquare,
	faUser,
	faTrashCan,
	faXmark,
	faCircleUser,
	faBars,
	faAngleRight,
	faEnvelope,
	faGear,
	faTableCells,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faAngleLeft,
	faMagnifyingGlass,
	faQuestion,
	faBell,
	faChartColumn,
	faHome,
	faArrowDown,
	faInfo,
	faPlus,
	faMinus,
	faUsers,
	faStar,
	faFileUpload,
	faFileDownload,
	faClone,
	faBan,
	faPaperPlane,
	faBackspace
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
	faPenToSquare,
	faUser,
	faTrashCan,
	faXmark,
	faCircleUser,
	faBars,
	faAngleRight,
	faEnvelope,
	faGear,
	faTableCells,
	faSort,
	faSortDown,
	faSortUp,
	faSpinner,
	faAngleLeft,
	faMagnifyingGlass,
	faQuestion,
	faBell,
	faChartColumn,
	faHome,
	faArrowDown,
	faInfo,
	faPlus,
	faMinus,
	faUsers,
	faStar,
	faFileUpload,
	faFileDownload,
	faClone,
	faBan,
	faPaperPlane,
	faBackspace
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
