import * as hasRead from "@/api/notifications/hasRead";

export default {
	namespaced: true,
	state: {
		id: null,
		error: null,
	},
	getters: {
		readId: (state) => state.id,
		readError: (state) => {
			return state.error;
		},
	},
	mutations: {
		SET_ID(state, payload) {
			state.id = payload;
		},
		SET_ERROR(state, payload) {
			state.error = payload;
		},
	},
	actions: {
		async hasRead({ commit, getters, rootGetters }) {
			let token = rootGetters["loginStore/readToken"];
			let hasReadRes = await hasRead
				.hasRead(token, getters.readId)
				.then((data) => {
					if (data) {
						return true;
					}
				})
				.catch((error) => {
					console.log(error.response.data.detail);
					if (error.response) {
						let message =
							error.response.data.message ||
							error.response.data.detail ||
							error.response.data.detail[0].msg;
						message == "Token expired"
							? commit("loginStore/SET_ACCESS_TOKEN", null, {
									root: true,
							  })
							: message
							? commit("SET_ERROR", message)
							: commit("SET_ERROR", "Algo salió mal");
					} else {
						console.log(error);
						commit("SET_ERROR", error);
					}
				});
			return hasReadRes;
		},
	},
};
